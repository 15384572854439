import React, { useMemo } from 'react';
import { BreakPoint, Typography, useBreakpoint } from '@decub8/ui';
import dayjs from 'dayjs';

import { EventType } from '@src/ts/constants';
import { ProjectEvent, ProjectToken } from '@src/ts/interfaces';
import { formatTime } from '@src/utils/format';

const getVestingDetails = (token: ProjectToken, event?: ProjectEvent) => {
    const {
        type,
        event_details: { vesting },
        token_price,
    } = event || { event_details: {} };

    const {
        fully_diluted_valuation,
        initial_market_cap,
        initial_market_cap_ex_liquidity,
        total_supply,
    } = token;

    const start_date = vesting ? new Date(vesting.start * 1000) : null;

    const formatted_start_date = start_date
        ? dayjs(start_date).utc().format('MMM D, YYYY | HH:mm UTC')
        : null;

    return [
        {
            value: !isNaN(vesting?.initial_unlock_percent)
                ? `${vesting?.initial_unlock_percent / 10}%`
                : 'TBA',
            label:
                event?.type === EventType.TokenClaim
                    ? 'Release on vesting start'
                    : 'Release on CEX listing',
        },
        {
            value: formatted_start_date || 'TBA',
            label:
                event?.type === EventType.TokenClaim
                    ? 'Vesting start'
                    : 'CEX listing',
        },
        {
            value: !isNaN(vesting?.cliff)
                ? vesting.cliff === 0
                    ? 'None'
                    : formatTime(vesting?.cliff)
                : 'TBA',
            label: 'Cliff duration',
        },
        {
            value: formatTime(vesting?.duration) || 'TBA',
            label: 'Vesting duration',
        },

        {
            value:
                type === EventType.TokenClaim
                    ? token_price
                    : token_price
                    ? `${token_price} USD`
                    : 'TBA',
            label: 'Event token price',
        },
        {
            value: fully_diluted_valuation,
            label: 'Fully diluted valuation',
        },
        {
            value: initial_market_cap,
            label: 'Initial market cap',
        },
        {
            value: initial_market_cap_ex_liquidity,
            label: 'Initial market cap excluding liquidity',
        },
        {
            value: total_supply,
            label: 'Total supply',
        },
    ].filter(({ value }) => !!value);
};

const VestingDetails: React.FC<{
    token: ProjectToken;
    event: ProjectEvent;
}> = ({ token, event }) => {
    const vesting_details = useMemo(
        () => getVestingDetails(token, event),
        [token, event],
    );

    const breakpoint = useBreakpoint();
    const is_mobile = breakpoint < BreakPoint.SM;
    return (
        <div className="max-w-[800px] mx-auto">
            {vesting_details.map(({ label, value }, index) => {
                const shouldApplyGradient = index % 2 === 0; // Apply gradient to even indices
                return (
                    <div
                        key={label + index + value}
                        className={`max-w-[780px] h-[60px] grid grid-cols-2 items-center px-8   ${
                            shouldApplyGradient
                                ? 'bg-gradient-to-r from-surface-level-one rounded-lg to-transparent'
                                : ''
                        }`}
                    >
                        <Typography variant="secondary" size="md">
                            {label}
                        </Typography>
                        <Typography size={is_mobile ? 'lg' : '2xl'} allBold>
                            {value}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};

export default VestingDetails;
