import { useContext, useEffect, useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { log } from '@logtail/next';
import dayjs from 'dayjs';

import { CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { IContractManager } from '@src/contracts/manager';
import { useAppSelector } from '@src/hooks';
import { GlobalContext, useGlobalContext } from '@src/hooks/useGlobalContext';
import {
    ContractType,
    EventType,
    IdentityVerifiedStatus,
} from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';

import { useProjectContext } from '../context';

import { getTokenClaimAlloc, is_hardcap_met } from './utils';

export const useCurrentEventStage = (min_tier: number): number => {
    const { _userTier } = useContext(GlobalContext);
    const { user } = useAppSelector((state) => state?.auth) || {};
    const { wallet_verified } = useAppSelector((state) => state?.auth) || {};
    const { ga_registered, crowdfunding, event } = useProjectContext();

    const { start_date, chainId } = event;

    const not_eligble =
        user?.identity_verified !== IdentityVerifiedStatus.VERIFIED ||
        !wallet_verified ||
        !_userTier ||
        !_userTier.flag ||
        _userTier.id < min_tier;

    const stage_event_functions = {
        [EventType.Crowdfunding]: () => {
            const has_date = !!start_date;
            const payment_token =
                CONTRACT.PaymentToken[chainId || DEFAULT_CHAIN_ID];

            const { total_raised = '0', hardcap = '0' } = crowdfunding || {};

            const hardcap_met = is_hardcap_met(
                payment_token.decimals,
                hardcap,
                total_raised,
            );

            const is_over =
                hardcap_met ||
                dayjs().isAfter(
                    dayjs(Number(start_date) * 1000).add(
                        event.event_details.durations.reduce(
                            (a, b) => a + b,
                            0,
                        ),
                        'seconds',
                    ),
                );

            const _not_eligble = not_eligble || !ga_registered;
            const start = dayjs(Number(start_date) * 1000);
            const is_started = dayjs().isAfter(
                dayjs(Number(start_date) * 1000),
            );
            const is_fcfs_1 = dayjs().isAfter(
                start.add(event.event_details.durations[0], 'seconds'),
            );
            const is_fcfs_2 = dayjs().isAfter(
                start.add(
                    event.event_details.durations[0] +
                        event.event_details.durations[1],
                    'seconds',
                ),
            );

            if (!has_date || (!is_over && _not_eligble) || !is_started)
                return 0;
            if (is_over) return 5;
            if (is_fcfs_2) return 3;
            if (is_fcfs_1) return 2;
            return 1;
        },
        [EventType.TokenClaim]: () => {
            const is_over = dayjs().isAfter(
                dayjs(dayjs(Number(start_date) * 1000)),
            );

            if (!is_over && not_eligble) return 0;
            if (is_over) return 3;
            return 1;
        },
    };

    return stage_event_functions[event.type]();
};

const getInvestmentsContractType = (
    event: ProjectEvent,
    contract_manager: IContractManager,
): { type: ContractType; is_legacy: boolean } => {
    const is_legacy = Number(event.start_date || '0') < 1679270400;

    if (is_legacy) return { type: ContractType.Investments, is_legacy };
    if (!event.contract?.address)
        return { type: ContractType.EventFactory, is_legacy };

    const event_contract = event.contract;
    const crowdfunding = contract_manager.getContractByAddressAndABI(
        event_contract.address,
        event_contract.abi,
    );

    if (crowdfunding.contract.setMerkleRoot)
        return { type: ContractType.EventFactory, is_legacy };

    return { type: ContractType.LegacyEventFactory, is_legacy };
};

export const useTokenClaimHasClaimed = (
    event: ProjectEvent,
): [boolean, BigNumber, () => Promise<void>] => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const [has_claimed, setHasClaimed] = useState(true);
    const [allocation, setLegacyAllocation] = useState(BigNumber.from(0));
    const { contract_manager } = useGlobalContext();
    const { is_legacy, type } = useMemo(
        () => getInvestmentsContractType(event, contract_manager),
        [event, contract_manager],
    );

    const { contract } = event;

    const update = ({ has_claimed, allocation }) => {
        setHasClaimed(has_claimed);
        setLegacyAllocation(allocation);
    };

    const { ga_registered } = useProjectContext();

    useEffect(() => {
        if (user) {
            getTokenClaimAlloc(
                user,
                contract,
                is_legacy,
                type,
                event.chainId || DEFAULT_CHAIN_ID,
                ga_registered,
                contract_manager,
            )
                .then(update)
                .catch((err) =>
                    log.error('error getting the token claim allocation', err),
                );
        }
    }, [user, contract, is_legacy, contract_manager]);

    return [
        has_claimed,
        allocation,
        () =>
            getTokenClaimAlloc(
                user,
                contract,
                is_legacy,
                type,
                event.chainId || DEFAULT_CHAIN_ID,
                ga_registered,
                contract_manager,
            ).then(update),
    ];
};
