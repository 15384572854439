import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { IDO_REG_CUTOFF_MINS } from '@src/constants';
import { EventType } from '@src/ts/constants';
import { EventStage, ProjectEvent } from '@src/ts/interfaces';

import { InvestInput } from './InvestInput';
import LearnToEarn from './LearnToEarn';
import { Qualify } from './Qualify';

dayjs.extend(utc);

export enum InvestmentRound {
    GA = 'GA',
    FCFS = 'FCFS',
    FCFS2 = 'FCFS2',
}

export const stages = {
    [EventType.Crowdfunding]: (
        project_id: string,
        event: ProjectEvent,
        ga_registered: boolean,
        payment_balance: string,
    ): EventStage[] => {
        const start =
            event?.start_date && dayjs(Number(event.start_date) * 1000);

        const cutoff = start.subtract(
            event.type === EventType.Crowdfunding ? IDO_REG_CUTOFF_MINS : 0,
            'minutes',
        );

        const now = dayjs();

        const has_reached_cutoff = now.isAfter(cutoff);

        return [
            {
                name: 'Whitelist for this event',
                component: Qualify,
                props: {
                    event,
                    min_tier: event.event_details.min_tier || 0,
                    ga_registered,
                },
                date: has_reached_cutoff ? 'TBA' : cutoff.unix().toString(),
            },
            {
                name: 'Guaranteed allocation',
                component: InvestInput,
                props: {
                    project_id,
                    event,
                    round: InvestmentRound.GA,
                    payment_balance,
                },
                date: event.start_date || 'TBA',
            },
            {
                name: 'FCFS round one',
                component: InvestInput,
                props: {
                    project_id,
                    event,
                    round: InvestmentRound.FCFS,
                    payment_balance,
                },
                date:
                    (event.start_date &&
                        start
                            .add(event.event_details.durations[0], 'seconds')
                            .unix()
                            .toString()) ||
                    'TBA',
            },
            {
                name: 'FCFS round two',
                component: InvestInput,
                props: {
                    project_id,
                    event,
                    round: InvestmentRound.FCFS2,
                    payment_balance,
                },
                date:
                    (event.start_date &&
                        start
                            .add(
                                event.event_details.durations[0] +
                                    event.event_details.durations[1],
                                'seconds',
                            )
                            .unix()
                            .toString()) ||
                    'TBA',
            },
            {
                name: 'Event complete',
                component: null,
                date:
                    (event.start_date &&
                        start
                            .add(
                                event.event_details.durations.reduce(
                                    (a, b) => a + b,
                                    0,
                                ),
                                'seconds',
                            )
                            .unix()
                            .toString()) ||
                    'TBA',
            },
        ];
    },
    [EventType.TokenClaim]: (
        project_id: string,
        event: ProjectEvent,
        scrollToLearnToEarn: () => void,
    ): EventStage[] => [
        {
            name: 'Qualify for this event',
            component: Qualify,
            props: { event, min_tier: event.event_details.min_tier || 0 },
        },
        {
            name: 'Learn to earn',
            component: LearnToEarn,
            props: { event, scrollToLearnToEarn, project_id },
        },
        {
            name: 'Claim',
            component: null,
            date: event.start_date || 'TBA',
        },
    ],
};
