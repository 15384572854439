import { useEffect, useMemo, useState } from 'react';
import { Alert, Card, Media, MediaVariant } from '@decub8/ui';
import dayjs from 'dayjs';

import { CONTENT, CONTRACT, DEFAULT_CHAIN_ID } from '@src/config';
import { IDO_REG_CUTOFF_MINS } from '@src/constants';
import { useAppSelector, useTokenBalance } from '@src/hooks';
import { EventType } from '@src/ts/constants';
import { ProjectEvent as IProjectEvent } from '@src/ts/interfaces';

import { useProjectContext } from '../context';

import { EventFooter } from './EventFooter';
import { EventHeader } from './EventHeader';
import { useCurrentEventStage } from './hooks';
import { ExpandableStages } from './Stages';
import { getStages, is_hardcap_met } from './utils';

export const ProjectEvent: React.FC<{
    project_id: string;
    scrollToLearnToEarn: () => void;
    event: IProjectEvent;
}> = ({ event, project_id, scrollToLearnToEarn }) => {
    const { user } = useAppSelector((state) => state?.auth) || {};
    const [is_mounted, setIsMounted] = useState(false);

    const { data_loading, ga_registered, crowdfunding } = useProjectContext();

    const { hardcap = '0', total_raised = '0' } = crowdfunding || {};

    const payment_token =
        CONTRACT.PaymentToken[event.chainId || DEFAULT_CHAIN_ID];

    const hardcap_met = is_hardcap_met(
        payment_token.decimals,
        hardcap,
        total_raised,
    );

    const is_crowdfunding = event.type === EventType.Crowdfunding;

    const event_chain = event?.chainId || DEFAULT_CHAIN_ID;

    const { address } = CONTRACT.PaymentToken[event_chain];

    const { min_tier = 0 } = event.event_details || {};

    const payment_balance = useTokenBalance(address, event_chain);

    const stage = useCurrentEventStage(min_tier);

    const options = useMemo(
        () =>
            getStages(
                project_id,
                event,
                scrollToLearnToEarn,
                ga_registered,
                payment_balance,
            ),
        [
            project_id,
            event,
            scrollToLearnToEarn,
            ga_registered,
            payment_balance,
        ],
    );

    const start = dayjs(Number(event.start_date) * 1000);

    const token_claim_end_date = start;
    const crowdfunding_end_date = start.add(
        event.event_details.durations.reduce((a, b) => a + b, 0),
        'seconds',
    );

    const now = dayjs();

    const is_crowdfunding_event_over = now.isAfter(crowdfunding_end_date);
    const is_token_claim_event_over = now.isAfter(token_claim_end_date);

    const has_reached_cutoff = now.isAfter(
        start.subtract(
            event.type === EventType.Crowdfunding ? IDO_REG_CUTOFF_MINS : 0,
            'minutes',
        ),
    );

    const show_missed_whitelist_message =
        user &&
        is_crowdfunding &&
        has_reached_cutoff &&
        !ga_registered &&
        !is_crowdfunding_event_over &&
        !hardcap_met;

    const loaded = useMemo(
        () =>
            [EventType.TokenClaim].includes(event.type) ||
            !event.contract?.address ||
            !event.contract?.abi ||
            !!crowdfunding,
        [event.type, event.contract, crowdfunding],
    );

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!is_mounted) return null;

    return (
        <Card className={`bg-surface-level-one`}>
            <EventHeader event={event} />
            {!loaded || data_loading ? (
                <div className="justify-center flex items-center h-60">
                    <Media variant="loader" className="w-16 h-16" />
                </div>
            ) : (
                <div className="px-5 py-5">
                    {show_missed_whitelist_message && (
                        <Alert
                            className="mb-5"
                            variant="note"
                            title="You are not whitelisted for this event"
                        >
                            To stay informed about future opportunities, join
                            our community to receive updates on upcoming events
                            and whitelist openings.
                            <span className="flex space-x-10 mt-[16px]">
                                {CONTENT.social
                                    .slice(0, 3)
                                    .map(({ type, url }) => (
                                        <a
                                            href={url}
                                            key={type + '-' + url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Media
                                                variant={type as MediaVariant}
                                                size={0}
                                                className="text-accent h-[18px]"
                                            />
                                        </a>
                                    ))}
                            </span>
                        </Alert>
                    )}
                    <ExpandableStages
                        has_reached_cutoff={has_reached_cutoff}
                        event={event}
                        options={options}
                        current={stage}
                        is_crowdfunding_event_over={is_crowdfunding_event_over}
                        is_token_claim_event_over={is_token_claim_event_over}
                        hardcap={hardcap}
                        total_raised={total_raised}
                    />

                    {stage >= options.length - 1 && (
                        <EventFooter event={event} />
                    )}
                </div>
            )}
        </Card>
    );
};
