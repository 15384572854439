import { useContext, useEffect, useState } from 'react';

import { InvestmentRound } from '@src/components/Project/Event/constants';
import { DEFAULT_CHAIN_ID } from '@src/config';
import { ProjectEvent } from '@src/ts/interfaces';

import { GlobalContext } from '../useGlobalContext';

export const useRoundMultiplier = (
    event: ProjectEvent,
): ((round: InvestmentRound) => number) => {
    const { contract_manager } = useContext(GlobalContext);
    const [multipliers, setMultipliers] = useState<number[]>([1, 2, 10]);

    useEffect(() => {
        if (!event || !contract_manager) {
            return;
        }
        const getRoundMultipliers = async () => {
            try {
                const contract = contract_manager.getContractByAddressAndABI(
                    event.contract.address,
                    event.contract.abi,
                    event.chainId || DEFAULT_CHAIN_ID,
                ).contract;

                const [second, third] = await Promise.all(
                    [0, 1].map((i) => contract.roundsMultipliers(i)),
                );

                setMultipliers([1, second.toNumber(), third.toNumber()]);
            } catch (e) {
                console.error('Error getting round multipliers', e);
            }
        };
        if (event && contract_manager) {
            getRoundMultipliers();
        }
    }, [event, contract_manager]);

    const getRoundMultiplier = (round: InvestmentRound) => {
        switch (round) {
            case InvestmentRound.FCFS2:
                return multipliers[2] || 10;
            case InvestmentRound.FCFS:
                return multipliers[1] || 2;
            default:
                return multipliers[0] || 1;
        }
    };

    return getRoundMultiplier;
};
