import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';

import { IContractManager } from '@src/contracts';
import { ContractType, EventType } from '@src/ts/constants';
import {
    EventStage,
    ProjectContract,
    ProjectEvent,
    User,
} from '@src/ts/interfaces';

import { stages } from './constants';

export const is_hardcap_met = (
    payment_token_decimals: number,
    hardcap = '0',
    total_raised = '0',
) => {
    // Check if the hardcap has been met - within range of 1c
    const one_cent = BigNumber.from(10).pow(payment_token_decimals - 2); // need to move decimal 2 places for 1c (0.01)

    const hardcap_met =
        BigNumber.from(hardcap).gt(0) &&
        BigNumber.from(total_raised).gt(BigNumber.from(hardcap).sub(one_cent));

    return hardcap_met;
};

export const getStages = (
    project_id: string,
    event: ProjectEvent,
    scrollToLearnToEarn: () => void,
    ga_registered: boolean,
    payment_balance: string,
): EventStage[] => {
    switch (event.type) {
        case EventType.TokenClaim:
            return stages[event.type](project_id, event, scrollToLearnToEarn);
        case EventType.Crowdfunding:
            return stages[event.type](
                project_id,
                event,
                ga_registered,
                payment_balance,
            );
        default:
            return [];
    }
};

export const shouldDisplayTime = (
    current: number,
    idx: number,
    total: number,
    event: ProjectEvent,
    has_reached_cutoff: boolean,
): boolean => {
    if ([EventType.TokenClaim].includes(event.type)) return current < idx;

    // else crowfunding
    const has_date = !!event.start_date;

    if (!has_date) return idx === 0;

    const start = dayjs(Number(event.start_date) * 1000);

    // rules for displaying whitelist closed timer
    if (idx === 0 && !has_reached_cutoff) return true;
    if (idx === 0 && current === 0 && has_reached_cutoff) return false;

    if (current === 1 && idx === 1 && dayjs().isBefore(start)) return true;

    if (
        current > 1 &&
        idx > current &&
        dayjs().isBefore(
            start.add(
                event.event_details.durations.reduce((a, b) => a + b, 0),
                'seconds',
            ),
        )
    )
        return true;

    if (dayjs().isBefore(start) || idx > 1) return current < idx && idx < total;

    return false;
};

export const nowLiveIndex = (event: ProjectEvent): number => {
    const has_date = !!event.start_date;
    const start = dayjs(Number(event.start_date) * 1000);
    const now = dayjs();
    if (has_date && now.isBefore(start)) return -1;

    let time = start;
    const { durations = [] } = event.event_details || {};

    for (let i = 0; i < durations.length; i++) {
        time = time.add(durations[i], 'seconds');
        if (
            has_date &&
            event.type === EventType.Crowdfunding &&
            now.isBefore(time)
        )
            return i + 1;
    }

    return -1;
};

export const getTokenClaimAlloc = async (
    user: User,
    contract: ProjectContract,
    is_legacy: boolean,
    contract_type: ContractType,
    chainId: number,
    ga_registered: boolean,
    contract_manager: IContractManager,
): Promise<{
    allocation: string;
    has_claimed: boolean;
}> => {
    const { abi, address } = contract || {};
    // remove warnings in console
    const ABI = JSON.parse(abi || '[]').filter(
        ({ name }) => name !== 'Initialized',
    );

    let total = BigNumber.from(0);
    let has_claimed = true;

    const investments = contract_manager.getContract(contract_type, chainId);

    if (is_legacy) {
        const { vestingActive: active, invested } =
            await investments.contract.getInvestmentInfo(
                user?.wallet_address,
                address,
            );

        if (active) {
            const { total: t } = await investments.contract.getVestingInfo(
                user.wallet_address,
                address,
            );
            total = t;
        }
        if (abi) {
            const token_claim = contract_manager.getContractByAddressAndABI(
                address,
                ABI,
                chainId && chainId,
            );
            const alloc = await token_claim.contract.userAllocation(
                user?.wallet_address,
            );

            if (
                alloc.active &&
                alloc.shares.gt(0) &&
                alloc.claimedAmount.eq(0)
            ) {
                has_claimed = false;
            } else {
                has_claimed = true;
            }
        }
        return {
            allocation: invested.gt(0) ? invested : total,
            has_claimed,
        };
    } else {
        const token_claim = contract_manager.getContractByAddressAndABI(
            address,
            ABI,
            chainId && chainId,
        );
        const alloc = await token_claim.contract.userAllocation(
            user?.wallet_address,
        );
        let is_new_system = false;

        // if not an object and just single big number, using new allocation system
        if (BigNumber.isBigNumber(alloc)) {
            is_new_system = true;
            has_claimed = ga_registered && alloc.gt(0);
        } else if (
            alloc.active &&
            alloc.shares.gt(0) &&
            alloc.claimedAmount.eq(0)
        ) {
            has_claimed = false;
        } else {
            has_claimed = true;
        }

        return {
            allocation: is_new_system ? alloc : alloc.claimedAmount,
            has_claimed,
        };
    }
};
