import React, { useContext, useMemo } from 'react';
import { Typography } from '@decub8/ui';
import Link from 'next/link';

import { ArrowRight } from '@src/components/Icon';
import { useAppSelector } from '@src/hooks/index';
import { GlobalContext } from '@src/hooks/useGlobalContext';
import { EventType, IdentityVerifiedStatus } from '@src/ts/constants';
import { ITier, IUserTier, ProjectEvent, User } from '@src/ts/interfaces';

import { EventRegister } from './Register';

const classes =
    'text-left w-full p-4 bg-surface-level-two hover:bg-surface-level-two flex rounded border border-border border-opacity-[0.03]';

export const useQualifySteps = (
    user: User,
    user_tier: IUserTier,
    setTierDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
    min_tier: number,
) => {
    const steps = useMemo(
        () => [
            {
                name: 'Register or sign in',
                complete: !!user,
                href: '/login',
                type: 'link',
            },
            {
                name: 'Verify ID',
                complete:
                    !user ||
                    user.identity_verified === IdentityVerifiedStatus.VERIFIED,
                href: '/verify/identity',
                type: 'link',
            },
            {
                name: 'Verify a wallet',
                complete: !user || user.wallet_address,
                href: '/verify/wallet',
                type: 'link',
            },
            {
                name: 'Get an investor tier',
                complete: !user || user_tier?.flag,
                type: 'button',
                onClick: () => setTierDrawerOpen(true),
            },
            {
                name: 'Upgrade your investor tier',
                complete: !user || !user_tier?.flag || user_tier.id >= min_tier,
                type: 'button',
                onClick: () => setTierDrawerOpen(true),
            },
        ],
        [user, user_tier, min_tier, setTierDrawerOpen],
    );

    return steps.filter(({ complete }) => !complete);
};

export const Qualify: React.FC<{
    event: ProjectEvent;
    min_tier: number;
    ga_registered: boolean;
}> = ({ event, min_tier }) => {
    const { _userTier, _tiers, _setTierDrawerOpen } = useContext(GlobalContext);
    const { user } = useAppSelector((state) => state.auth);

    const steps_to_show = useQualifySteps(
        user,
        _userTier,
        _setTierDrawerOpen,
        min_tier,
    );

    // helper function to get the correct tier name based on the tier index
    // previously we could just use the index to get the tier name, but now we need to get the tier using the idx to find the tier.id that matches the idx
    const getCorrectTier = (tier_idx: number): ITier => {
        try {
            return _tiers.find((tier) => tier.id === tier_idx);
        } catch (e) {
            return null;
        }
    };

    return (
        <div className="space-y-4">
            {steps_to_show.map(({ name, href, type, onClick }) =>
                type === 'link' ? (
                    <Link
                        href={href}
                        key={`${name} + ${href}`}
                        className={classes}
                    >
                        <div className="flex-1">
                            <Typography
                                variant="custom"
                                className="hover:text-primary text-secondary"
                            >
                                {name}
                            </Typography>
                        </div>
                        <div className="text-accent">
                            <ArrowRight />
                        </div>
                    </Link>
                ) : (
                    <>
                        {name === 'Upgrade your investor tier' && (
                            <Typography size="sm" variant="secondary">
                                To whitelist for this event, you must be at
                                least a{' '}
                                <span className="text-white text-bold">
                                    {' '}
                                    {getCorrectTier(min_tier)?.name}{' '}
                                </span>{' '}
                                tier.
                            </Typography>
                        )}
                        <button
                            className={classes}
                            onClick={onClick}
                            key={name}
                        >
                            <div className="flex-1">
                                <Typography className="hover:text-primary text-secondary">
                                    {name}
                                </Typography>
                            </div>
                            <div className="text-accent">
                                <ArrowRight />
                            </div>
                        </button>
                    </>
                ),
            )}
            {event.type === EventType.Crowdfunding &&
                steps_to_show.length === 0 && <EventRegister event={event} />}
        </div>
    );
};
