import React from 'react';
import { RegistrationSteps } from '@decub8/ui';

const OnboardingHeader: React.FC<{ current_step: number }> = ({
    current_step,
}) => {
    return (
        <div className="max-w-[580px] mx-auto my-[60px]">
            <RegistrationSteps
                current_step={current_step}
                className="mt-[60px]"
            />
        </div>
    );
};

export default OnboardingHeader;
